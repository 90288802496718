/* eslint-disable @typescript-eslint/camelcase */
import { Commodity, CommodityList } from "@/domain/entities/Commodity";
import { Pagination } from "@/domain/entities/Pagination";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CommodityPresenter } from "../presenters/CommodityPresenter";
import {
  EditCommodityApiRequest,
  RequestListCommodity
} from "@/data/payload/api/CommodityApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import {
  parsingErrorResponse,
  updateLocalStorageFeatureFlag
} from "@/app/infrastructures/misc/Utils";

export interface CommodityState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  commodities: CommodityList;
  commodity: Commodity;
  isError: boolean;
  openModal: boolean;
  errorCause: string;
  search: string;
  status: string;
  minimumPriceView: string;
  modalSuccess: boolean;
  isStatusCommodity: boolean;
  isSurchangeApplicable: boolean;
  isDocumentSurcharge: boolean;
  isErrorEdit: boolean;
  messageSuccess: string;
  modalSave: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "commodity" })
class CommodityStore extends VuexModule implements CommodityState {
  public commodity = new Commodity();
  public isLoading = false;
  public isLoadingDetail = false;
  public commodities = new CommodityList(new Pagination(1, 20), []);
  public isError = false;
  public openModal = false;
  public errorCause = "";
  public search = "";
  public status = "";
  public minimumPriceView = "0";
  public modalSuccess = false;
  public isStatusCommodity = false;
  public isSurchangeApplicable = false;
  public isDocumentSurcharge = false;
  public isErrorEdit = false;
  public messageSuccess = "";
  public modalSave = false;

  @Action
  public getCommodityList(params: RequestListCommodity) {
    this.setLoading(true);
    const presenter = container.resolve(CommodityPresenter);
    return presenter
      .getCommodityList(params)
      .then((res: CommodityList) => {
        this.setCommodityList(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((err: any) => {
        updateLocalStorageFeatureFlag("commodity");
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return new CommodityList(new Pagination(1, 10), []);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }
  @Mutation
  public setListToFirstPage() {
    this.commodities.pagination = new Pagination(1, 20);
  }
  @Action
  public initListCommodities() {
    this.setListToFirstPage();
    this.setSearch("");
    this.setStatus("");
    this.setError(false);
    this.setErrorCause("");
    this.getCommodityList(
      new RequestListCommodity({
        isTotalData: true
      })
    );
  }
  @Action
  public fetchListCommodities() {
    this.getCommodityList(
      new RequestListCommodity({
        page: this.commodities.pagination.page,
        limit: this.commodities.pagination.limit,
        isTotalData: true,
        search: this.search,
        commodityStatus: this.status,
        cache: false
      })
    );
  }

  @Action
  public getCommodityDetail(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(CommodityPresenter);
    presenter
      .getDetailCommodity(params.id)
      .then((res: Commodity) => {
        this.setCommodityDetail(res);
        this.setMinimumPriceView(
          new Intl.NumberFormat().format(
            Number(this.commodity.commodity_min_price)
          )
        );
        this.setStatusCommodity(
          this.commodity.commodity_status.toLowerCase() === "active"
        );
        this.setSurchargeApplicable(
          this.commodity.commodity_surcharge_applicable?.toLowerCase() === "yes"
        );
        this.setDocumentSurcharge(
          this.commodity.commodity_document_surcharge?.toLowerCase() === "yes"
        );
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        updateLocalStorageFeatureFlag("commodity");
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public editCommodity(params: {
    commodity_id: number;
    description: string;
    hs_code: string;
    surcharge_applicable: string;
    document_surcharge: string;
    commodity_service_type: string;
    min_price: number;
    status: string;
  }) {
    this.setLoadingDetail(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CommodityPresenter);
    presenter
      .editCommodity(
        new EditCommodityApiRequest(
          params.commodity_id,
          params.description,
          params.hs_code,
          params.surcharge_applicable,
          params.document_surcharge,
          params.commodity_service_type,
          params.min_price,
          params.status
        )
      )
      .then((res: ResponsePayload) => {
        if (res.success) {
          this.setModalSuccess(true);
          this.setErrorEdit(false);
          this.setErrorCause("");
          this.setMessageSuccces(
            `Detail Commodity “${this.commodity.commodity_name} - ${this.commodity.commodity_code}” berhasil diubah`
          );
        }
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Commodity Gagal!", () =>
            this.editCommodity(params)
          )
        );
      })
      .finally(() => {
        this.setLoadingDetail(false);
        this.setModalSave(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public handleError() {
    this.setErrorEdit(false);
  }

  @Mutation
  public setModalSave(bool: boolean) {
    this.modalSave = bool;
  }

  @Mutation
  public setMessageSuccces(string: string) {
    this.messageSuccess = string;
  }

  @Mutation
  public setErrorEdit(bool: boolean) {
    this.isErrorEdit = bool;
  }

  @Mutation
  public setModalSuccess(bool: boolean) {
    this.modalSuccess = bool;
  }

  @Mutation
  public setSurchargeApplicable(bool: boolean) {
    this.isSurchangeApplicable = bool;
  }

  @Mutation
  public setDocumentSurcharge(bool: boolean) {
    this.isDocumentSurcharge = bool;
  }

  @Mutation
  public setStatusCommodity(bool: boolean) {
    this.isStatusCommodity = bool;
  }

  @Mutation
  public setCommodityList(data: CommodityList) {
    this.commodities = data;
  }

  @Mutation
  public setCommodityDetail(data: Commodity) {
    this.commodity = data;
  }

  @Mutation
  public setModalOpenSave(bool: boolean) {
    this.openModal = bool;
  }

  @Mutation
  public setMinimumPriceView(str: string) {
    this.minimumPriceView = str;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  // List Options Select
  get commodityListOptions() {
    const newArr: Array<any> = this.commodities.data.map(e => ({
      name: e.commodity_name,
      code: e.commodity_id
    }));
    return newArr;
  }
}

export const CommodityController = getModule(CommodityStore);
