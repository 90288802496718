/* eslint-disable @typescript-eslint/camelcase */
import { EditCommodityRequestInterface } from "../contracts/CommodityRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { isUrlUpdateVersion } from "@/app/infrastructures/misc/Utils";

export class EditCommodityApiRequest implements EditCommodityRequestInterface {
  private readonly commodity_id: number;
  private readonly description: string;
  private readonly hs_code: string;
  private readonly surcharge_applicable: string;
  private readonly document_surcharge: string;
  private readonly commodity_service_type: string;
  private readonly min_price: number;
  private readonly status: string;

  constructor(
    commodity_id: number,
    description: string,
    hs_code: string,
    surcharge_applicable: string,
    document_surcharge: string,
    commodity_service_type: string,
    min_price: number,
    status: string
  ) {
    this.commodity_id = commodity_id;
    this.description = description;
    this.hs_code = hs_code;
    this.surcharge_applicable = surcharge_applicable;
    this.document_surcharge = document_surcharge;
    this.commodity_service_type = commodity_service_type;
    this.min_price = min_price;
    this.status = status;
  }
  public toJSON(): string {
    return JSON.stringify({
      commodity_id: this.commodity_id,
      description: this.description,
      hs_code: this.hs_code,
      surcharge_applicable: this.surcharge_applicable,
      document_surcharge: this.document_surcharge,
      commodity_service_type: this.commodity_service_type,
      min_price: this.min_price,
      status: this.status
    });
  }
}

export class RequestListCommodity {
  cache = true;
  search = "";
  commodityStatus = "";
  page = 1;
  limit = 10;
  version = isUrlUpdateVersion("commodity");
  isTotalData = false;

  constructor(fields?: Partial<RequestListCommodity>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
